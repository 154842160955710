import React from 'react'
import { graphql } from 'gatsby'
import HubspotForm from 'react-hubspot-form'

import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import RightSide from '../../components/rightside-general'

import BG from '../../images/awards-bg.jpg'

class BookAFree extends React.Component {
	componentDidMount() {
		const script = document.createElement('script')
		script.src = '//js.hsforms.net/forms/shell.js'
		script.async = true

		document.getElementById('book-a-free-form').appendChild(script)
	}

	render() {
		const siteTitle = 'Book A Free'
		const siteDescription = this.props.data.site.siteMetadata.siteDescription

		return (
			<Layout title={siteTitle} location={this.props.location}>
				<SEO title={siteTitle} description={siteDescription} />
				<div
					id="sliderhead"
					style={{
						background: `url(${BG}) 50% 13px fixed rgb(34, 34, 34`,
						position: 'relative',
					}}
					className="blog-header2"
				/>
				<div className="container">
					<div className="row">
						<div className="col-md-8 left-list">
							<div id="contentgen">
								<h3 className="mb-3">Book a Free Initial Consultation</h3>
								<div id="book-a-free-form" ref={el => (this.div = el)} />
								<HubspotForm
									portalId="4217094"
									formId="08318452-fe9b-4083-88ea-5cfea9a73c84"
									onSubmit={() => console.log('Submit!')}
									onReady={form => console.log('Form ready!')}
									loading={<div>Loading...</div>}
								/>
							</div>
						</div>
						<div className="col-md-4 right-list pull-right">
							<RightSide />
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default BookAFree

export const BookAFreePageQuery = graphql`
	query BookAFreePageQuery {
		site {
			siteMetadata {
				title
				author
				description
			}
		}
	}
`
